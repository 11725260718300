//
// Buttons Base
//

// Button
.main-btn{
  display: inline-block;
  font-weight: 500;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 0px;
  padding: 0 50px;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 3px;
  line-height: 74px;
  border-radius: 0px;
  color: #1b1f2e;
  cursor: pointer;
  z-index: 5;
  -webkit-transition: all 0.4s ease-out 0s;
  -moz-transition: all 0.4s ease-out 0s;
  -ms-transition: all 0.4s ease-out 0s;
  -o-transition: all 0.4s ease-out 0s;
  transition: all 0.4s ease-out 0s;
  background-color: #29f0b4;
  position: relative;
  &:not(:disabled)::before{
    position: absolute;
    content: "";
    right: 0;
    top: 0;
    height: 17px;
    width: 17px;
    background: #ffd54f;
    -webkit-transition: all 0.3s ease-out 0s;
    -moz-transition: all 0.3s ease-out 0s;
    -ms-transition: all 0.3s ease-out 0s;
    -o-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
    z-index: -1;

  }
  &:disabled{
    background-color: #e9ecef;
    color: #adb5bd;
    border-color: #adb5bd;
  }
  &:not(:disabled):hover{
    background-color: #1b1f2e;
    color: #fff;
  }
  &:not(:disabled):hover::before {
    width: 100%;
    height: 100%;
  }
  &.main-btn-2{
    background-color: #ffd54f;
    color: #1b1f2e;
  }
  &.main-btn-2::before{
    background: #fff;
  }
  &.main-btn-2:hover{
    background-color: #fff;
    color: #1b1f2e;
  }
  &.main-btn-3,{
    background-color: #f6fffc;
    color: #1b1f2e;
  }
  &.main-btn-3::before,{
    background: #29f0b4;
  }
  &.main-btn-3:hover,{
    background-color: #29f0b4;
    color: #1b1f2e;
  }
}
.main-btn-small {
  display: inline-block;
  font-weight: 500;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 0px;
  padding: 0 50px;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 1px;
  line-height: 44px;
  border-radius: 10px;
  cursor: pointer;
  z-index: 5;
  -webkit-transition: all 0.4s ease-out 0s;
  -moz-transition: all 0.4s ease-out 0s;
  -ms-transition: all 0.4s ease-out 0s;
  -o-transition: all 0.4s ease-out 0s;
  transition: all 0.4s ease-out 0s;
  position: relative;
  [data-bs-theme='light'] & {
    background-color: #29f0b4;
    color: white;
  }
  [data-bs-theme='dark'] & {
    background-color: #2b2b40;
    color: #7e8299;
  }
  &:not(:disabled)::before{
    position: absolute;
    content: "";
    right: 0;
    top: 0;
    height: 17px;
    width: 17px;
    -webkit-transition: all 0.3s ease-out 0s;
    -moz-transition: all 0.3s ease-out 0s;
    -ms-transition: all 0.3s ease-out 0s;
    -o-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
    z-index: -1;
    border-top-right-radius: 10px;
    [data-bs-theme='light'] & {
      background: #ffd54f;
    }
    [data-bs-theme='dark'] & {
      background: #29f0b4;
    }
  }

  &:not(:disabled):hover{
    [data-bs-theme='light'] & {
      background-color: #ffd54f;
      color: #fff;
    }
    [data-bs-theme='dark'] & {
      background-color: #29f0b4;
      color: #1b1f2e;
    }
  }
  &:not(:disabled):hover::before {
    width: 100%;
    height: 100%;
    border-radius: 10px;
  }
  &:disabled{
    [data-bs-theme='light'] & {
      background-color: #e9ecef;
      color: #adb5bd;
      border-color: #adb5bd;
    }
    [data-bs-theme='dark'] & {
      background-color: #252525a3;
      color: #383854;
      border-color: #2b2b40;
    }
  }
  &.main-btn-2{
    [data-bs-theme='light'] & {
      background-color: #1b1f2e;
      color: #fff;
    }
    [data-bs-theme='dark'] & {
      background-color: #1b1f2e;
      color: #fff;
    }
  }
  &.main-btn-2::before{
    [data-bs-theme='light'] & {
      background: #fff;
    }
    [data-bs-theme='dark'] & {
      background: #fff;
    }
  }
  &.main-btn-2:hover{
    [data-bs-theme='light'] & {
      background-color: #fff;
      color: #1b1f2e;
    }
    [data-bs-theme='dark'] & {
      background-color: #fff;
      color: #1b1f2e;
    }
  }
  &.main-btn-3,{
    [data-bs-theme='light'] & {
      background-color: #f6fffc;
      color: #1b1f2e;
    }
    [data-bs-theme='dark'] & {
      background-color: #2b2b40;
      color: #7e8299;
    }
  }
  &.main-btn-3::before,{
      background: #29f0b4 !important;
  }
  &.main-btn-3:hover,{
      background-color: #29f0b4;
      color: #1b1f2e;
  }
}
.main-btn-small-idel {
  width: 45px;
  display: inline-block;
  font-weight: 500;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 0px;
  padding: 0px;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 1px;
  line-height: 44px;
  border-radius: 10px;
  cursor: pointer;
  z-index: 5;
  -webkit-transition: all 0.4s ease-out 0s;
  -moz-transition: all 0.4s ease-out 0s;
  -ms-transition: all 0.4s ease-out 0s;
  -o-transition: all 0.4s ease-out 0s;
  transition: all 0.4s ease-out 0s;
  position: relative;
  [data-bs-theme='light'] & {
    background-color: #E91E63;
    color: white;
  }
  [data-bs-theme='dark'] & {
    background-color: #2b2b40;
    color: #7e8299;
  }
  &:not(:disabled)::before{
    position: absolute;
    content: "";
    right: 0;
    top: 0;
    height: 13px;
    width: 13px;
    -webkit-transition: all 0.3s ease-out 0s;
    -moz-transition: all 0.3s ease-out 0s;
    -ms-transition: all 0.3s ease-out 0s;
    -o-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
    z-index: -1;
    border-top-right-radius: 10px;
    [data-bs-theme='light'] & {
      background: #E91E63;
    }
    [data-bs-theme='dark'] & {
      background: #E91E63;
    }
  }

  &:not(:disabled):hover{
    [data-bs-theme='light'] & {
      background-color: #E91E63;
      color: #fff;
    }
    [data-bs-theme='dark'] & {
      background-color: #E91E63;
      color: #1b1f2e;
    }
  }
  &:not(:disabled):hover::before {
    width: 100%;
    height: 100%;
    border-radius: 10px;
  }
  &:disabled{
    [data-bs-theme='light'] & {
      background-color: #e9ecef;
      color: #adb5bd;
      border-color: #adb5bd;
    }
    [data-bs-theme='dark'] & {
      background-color: #252525a3;
      color: #383854;
      border-color: #2b2b40;
    }
  }
  &.main-btn-2{
    [data-bs-theme='light'] & {
      background-color: #1b1f2e;
      color: #fff;
    }
    [data-bs-theme='dark'] & {
      background-color: #1b1f2e;
      color: #fff;
    }
  }
  &.main-btn-2::before{
    [data-bs-theme='light'] & {
      background: #fff;
    }
    [data-bs-theme='dark'] & {
      background: #fff;
    }
  }
  &.main-btn-2:hover{
    [data-bs-theme='light'] & {
      background-color: #fff;
      color: #1b1f2e;
    }
    [data-bs-theme='dark'] & {
      background-color: #fff;
      color: #1b1f2e;
    }
  }
  &.main-btn-3,{
    [data-bs-theme='light'] & {
      background-color: #E91E63;
      color: #1b1f2e;
    }
    [data-bs-theme='dark'] & {
      background-color: #E91E63;
      color: #7e8299;
    }
  }
  &.main-btn-3::before,{
      background: #ffd54f !important;
  }
  &.main-btn-3:hover,{
      background-color: #ffd54f;
      color: #1b1f2e;
  }
  &.i.bi:hover, i[class^=fonticon-]:hover, i[class*=" fonticon-"]:hover, i[class^=fa-]:hover, i[class*=" fa-"]:hover, i[class^=la-]:hover, i[class*=" la-"]:hover{
    color:#FFFFFF !important;
    z-index:99;
  }
  &.i.bi:before, i[class^=fonticon-]:before, i[class*=" fonticon-"]:before, i[class^=fa-]:before, i[class*=" fa-"]:before, i[class^=la-]:before, i[class*=" la-"]:before{
    color:#FFFFFF !important;
  }

}
.main-btn-small-iadd {
  width: 45px;
  display: inline-block;
  font-weight: 500;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 0px;
  padding: 0px;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 1px;
  line-height: 44px;
  border-radius: 10px;
  cursor: pointer;
  z-index: 5;
  -webkit-transition: all 0.4s ease-out 0s;
  -moz-transition: all 0.4s ease-out 0s;
  -ms-transition: all 0.4s ease-out 0s;
  -o-transition: all 0.4s ease-out 0s;
  transition: all 0.4s ease-out 0s;
  position: relative;
  [data-bs-theme='light'] & {
    background-color: #29f0b4;
    color: white;
  }
  [data-bs-theme='dark'] & {
    background-color: #2b2b40;
    color: #7e8299;
  }
  &:not(:disabled)::before{
    position: absolute;
    content: "";
    right: 0;
    top: 0;
    height: 13px;
    width: 13px;
    -webkit-transition: all 0.3s ease-out 0s;
    -moz-transition: all 0.3s ease-out 0s;
    -ms-transition: all 0.3s ease-out 0s;
    -o-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
    z-index: -1;
    border-top-right-radius: 10px;
    [data-bs-theme='light'] & {
      background: #29f0b4;
    }
    [data-bs-theme='dark'] & {
      background: #29f0b4;
    }
  }

  &:not(:disabled):hover{
    [data-bs-theme='light'] & {
      background-color: #29f0b4;
      color: #fff;
    }
    [data-bs-theme='dark'] & {
      background-color: #29f0b4;
      color: #1b1f2e;
    }
  }
  &:not(:disabled):hover::before {
    width: 100%;
    height: 100%;
    border-radius: 10px;
  }
  &:disabled{
    [data-bs-theme='light'] & {
      background-color: #e9ecef;
      color: #adb5bd;
      border-color: #adb5bd;
    }
    [data-bs-theme='dark'] & {
      background-color: #252525a3;
      color: #383854;
      border-color: #2b2b40;
    }
  }
  &.main-btn-2{
    [data-bs-theme='light'] & {
      background-color: #1b1f2e;
      color: #fff;
    }
    [data-bs-theme='dark'] & {
      background-color: #1b1f2e;
      color: #fff;
    }
  }
  &.main-btn-2::before{
    [data-bs-theme='light'] & {
      background: #fff;
    }
    [data-bs-theme='dark'] & {
      background: #fff;
    }
  }
  &.main-btn-2:hover{
    [data-bs-theme='light'] & {
      background-color: #fff;
      color: #1b1f2e;
    }
    [data-bs-theme='dark'] & {
      background-color: #fff;
      color: #1b1f2e;
    }
  }
  &.main-btn-3,{
    [data-bs-theme='light'] & {
      background-color: #29f0b4;
      color: #1b1f2e;
    }
    [data-bs-theme='dark'] & {
      background-color: #29f0b4;
      color: #7e8299;
    }
  }
  &.main-btn-3::before,{
      background: #ffd54f !important;
  }
  &.main-btn-3:hover,{
      background-color: #ffd54f;
      color: #1b1f2e;
  }
  &.i.bi:hover, i[class^=fonticon-]:hover, i[class*=" fonticon-"]:hover, i[class^=fa-]:hover, i[class*=" fa-"]:hover, i[class^=la-]:hover, i[class*=" la-"]:hover{
    color:#FFFFFF !important;
    z-index:99;
  }
  &.i.bi:before, i[class^=fonticon-]:before, i[class*=" fonticon-"]:before, i[class^=fa-]:before, i[class*=" fa-"]:before, i[class^=la-]:before, i[class*=" la-"]:before{
    color:#FFFFFF !important;
  }

}
.main-btn-back{
  display: inline-block;
  font-weight: 500;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 0px;
  padding: 0 50px;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 3px;
  line-height: 54px;
  border-radius: 2px;
  color: #1b1f2e;
  cursor: pointer;
  z-index: 5;
  -webkit-transition: all 0.4s ease-out 0s;
  -moz-transition: all 0.4s ease-out 0s;
  -ms-transition: all 0.4s ease-out 0s;
  -o-transition: all 0.4s ease-out 0s;
  transition: all 0.4s ease-out 0s;
  background-color: #29f0b4;
  position: relative;
  [data-bs-theme='light'] & {
    background-color: #29f0b4;
    color: white;
  }
  [data-bs-theme='dark'] & {
    background-color: #2b2b40;
    color: #7e8299;
  }
  &:not(:disabled)::before{
    position: absolute;
    content: "";
    right: 0;
    top: 0;
    height: 17px;
    width: 17px;
    -webkit-transition: all 0.3s ease-out 0s;
    -moz-transition: all 0.3s ease-out 0s;
    -ms-transition: all 0.3s ease-out 0s;
    -o-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
    z-index: -1;
    border-top-right-radius: 2px;
    [data-bs-theme='light'] & {
      background: #ffd54f;
    }
    [data-bs-theme='dark'] & {
      background: #29f0b4;
    }
  }

  &:not(:disabled):hover{
    [data-bs-theme='light'] & {
      background-color: #ffd54f;
      color: #fff;
    }
    [data-bs-theme='dark'] & {
      background-color: #29f0b4;
      color: #1b1f2e;
    }
  }
  &:not(:disabled):hover::before {
    width: 100%;
    height: 100%;
    border-radius: 10px;
  }
  &:disabled{
    [data-bs-theme='light'] & {
      background-color: #e9ecef;
      color: #adb5bd;
      border-color: #adb5bd;
    }
    [data-bs-theme='dark'] & {
      background-color: #252525a3;
      color: #383854;
      border-color: #2b2b40;
    }
  }
  &.main-btn-2{
    [data-bs-theme='light'] & {
      background-color: #1b1f2e;
      color: #fff;
    }
    [data-bs-theme='dark'] & {
      background-color: #1b1f2e;
      color: #fff;
    }
  }
  &.main-btn-2::before{
    [data-bs-theme='light'] & {
      background: #fff;
    }
    [data-bs-theme='dark'] & {
      background: #fff;
    }
  }
  &.main-btn-2:hover{
    [data-bs-theme='light'] & {
      background-color: #fff;
      color: #1b1f2e;
    }
    [data-bs-theme='dark'] & {
      background-color: #fff;
      color: #1b1f2e;
    }
  }
  &.main-btn-3,{
    [data-bs-theme='light'] & {
      background-color: #f6fffc;
      color: #1b1f2e;
    }
    [data-bs-theme='dark'] & {
      background-color: #2b2b40;
      color: #7e8299;
    }
  }
  &.main-btn-3::before,{
    background: #29f0b4 !important;
  }
  &.main-btn-3:hover,{
    background-color: #29f0b4;
    color: #1b1f2e;
  }
}
.btn {
  --#{$prefix}btn-color: var(--#{$prefix}body-color);


  // Reset outline
  outline: none !important;

  // Reset focus shadow
  &:not(.btn-shadow):not(.shadow):not(.shadow-sm):not(.shadow-lg):not(.shadow-xs) {
    box-shadow: none;
  }

  // Remove border
  &:not(.btn-outline):not(.btn-dashed):not(.border-hover):not(.border-active):not(.btn-flush):not(.btn-icon) {
    border: 0;
    padding: calc(#{$btn-padding-y} + #{$btn-border-width})
      calc(#{$btn-padding-x} + #{$btn-border-width});

    &.btn-lg {
      padding: calc(#{$btn-padding-y-lg} + #{$btn-border-width})
        calc(#{$btn-padding-x-lg} + #{$btn-border-width});
    }

    &.btn-sm {
      padding: calc(#{$btn-padding-y-sm} + #{$btn-border-width})
        calc(#{$btn-padding-x-sm} + #{$btn-border-width});
    }
  }

  // Link
  &.btn-link {
    border: 0;
    border-radius: 0;
    padding-left: 0 !important;
    padding-right: 0 !important;
    text-decoration: none;
    font-weight: $btn-font-weight;
  }

  // Outline
  &.btn-outline:not(.btn-outline-dashed) {
    border: 1px solid $input-border-color;
  }

  // Outline dashed
  &.btn-outline-dashed {
    border: 1px dashed $input-border-color;
  }

  // Flush
  &.btn-flush {
    @include button-reset();
  }

  // Flex
  &.btn-flex {
    display: inline-flex;
    align-items: center;
  }

  // Align start
  &.btn-trim-start {
    justify-content: flex-start !important;
    padding-left: 0 !important;
  }

  // Align start
  &.btn-trim-end {
    justify-content: flex-end !important;
    padding-right: 0 !important;
  }
}


// helpAI
.helpAI {
  background-color:#000000;
  color:#FFF;
  border-radius:5px;
  min-width:150px;
  font-size:12px;
}
// Reset
.btn-reset {
  background-color: transparent;
  border: 0;
  box-shadow: none;
  user-select: none;
  outline: none;
}

// Icons
.btn {
  // Font icon
  > i {
    display: inline-flex;
    font-size: $font-size-base;
    padding-right: 0.35rem;
    vertical-align: middle;
    //line-height: 0;
  }

  // Svg icon
  .svg-icon {
    flex-shrink: 0;
    line-height: 0;
    margin-right: 0.5rem;
  }

  // Icon only button
  &.btn-icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    height: $input-height;
    width: $input-height;

    // Remove border
    &:not(.btn-outline):not(.btn-dashed):not(.border-hover):not(.border-active):not(.btn-flush) {
      border: 0;
    }

    // Sizes
    &.btn-sm {
      height: $input-height-sm;
      width: $input-height-sm;
    }

    &.btn-lg {
      height: $input-height-lg;
      width: $input-height-lg;
    }

    &.btn-circle {
      border-radius: 50%;
    }

    i,
    .svg-icon {
      padding: 0;
      margin: 0;
      line-height: 1;
    }
  }
}
